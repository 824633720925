import type { NotificationReceivedActivityLogViewModel } from "@/lib/backend/core.api";

export function getNotificationTypeText(
	type: NonNullable<NotificationReceivedActivityLogViewModel["notificationType"]>,
): string {
	switch (type) {
		case "afspraak-geannuleerd":
			return "Afspraak geannuleerd";
		case "afspraak-gemist":
			return "Afspraak gemist";
		case "afspraak-reservering":
			return "Afspraak reservering";
		case "afval-tips":
			return "Afval tips";
		case "arr-afgesloten-lid":
			return "Arrangement afgesloten";
		case "arr-annuleren-lid":
			return "Arrangement annuleren";
		case "compliment-aantal-bezoeken":
			return "Compliment aantal bezoeken";
		case "compliment-aantal-groepslessen":
			return "Compliment aantal groepslessen";
		case "compliment-over-meting":
			return "Compliment over meting";
		case "encouragement-gym-visit":
			return "Aanmoediging bezoek aan de sportschool";
		case "geen-doel-opgesteld":
			return "Geen doel opgesteld";
		case "herhaling-afgemeld":
			return "Herhaling afgemeld";
		case "jaar-rapport":
			return "Jaar rapport";
		case "kwartaal-rapport":
			return "Kwartaal rapport";
		case "lang-niet-geweest":
			return "Lang niet geweest";
		case "les-bijna-vol":
			return "Les bijna vol";
		case "les-geannuleerd":
			return "Les geannuleerd";
		case "les-gemist":
			return "Les gemist";
		case "les-gewijzigd":
			return "Les gewijzigd";
		case "les-herinnering":
			return "Les herinnering";
		case "les-reservering":
			return "Les reservering";
		case "maand-rapport":
			return "Maand rapport";
		case "meting-4-weken-oud":
			return "Meting 4 weken oud";
		case "schema-6-weken-oud":
			return "Schema 6 weken oud";
		case "specifieke-datum-notificatie":
			return "Specifieke datum notificatie";
		case "verjaardag":
			return "Verjaardag";
		case "spierballen-arrangement":
			return "Spierballen arrangement";
		case "tijdslot-geannuleerd":
			return "Tijdslot geannuleerd";
		case "tijdslot-gemist":
			return "Tijdslot gemist";
		case "tijdslot-gewijzigd":
			return "Tijdslot gewijzigd";
		case "tijdslot-reservering":
			return "Tijdslot reservering";
		case "vaker-fitness-afspraak":
			return "Vaker fitness afspraak";
		case "vaste-sportdag":
			return "Vaste sportdag";
		case "vriend-aansporen":
			return "Vriend aansporen";
		case "vriend-al-geweest":
			return "Vriend al geweest";
		case "vriend-les-uitnodiging":
			return "Vriend les uitnodiging";
		case "vriend-uitnoding":
			return "Vriend uitnoding";
		case "zonnebank-tip":
			return "Zonnebank tip";
		default:
			return type;
	}
}
