






































import { type PropType, defineComponent } from "@vue/composition-api";
import type { ActivityLogViewModel } from "@/lib/backend/core.api";
import Card from "../parts/Card.vue";
import Time from "../parts/Time.vue";
import Title from "../parts/Title.vue";

export default defineComponent({
	components: { Card, SbTime: Time, SbTitle: Title },
	props: {
		day: {
			type: String,
			required: true,
		},
		time: {
			type: String,
			required: true,
		},
		log: {
			type: Object as PropType<ActivityLogViewModel>,
			required: true,
		},
	},
});
