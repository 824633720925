












/* eslint-disable @typescript-eslint/no-explicit-any */
import { type PropType, defineComponent } from "@vue/composition-api";
import { buttonVariants } from "@/lib/pfg/components/button";
import type { VariantProps } from "@/lib/style/cva.config";

export default defineComponent<any, any, any, any, any, any, any, any, any>({
	props: {
		variant: {
			type: String as PropType<VariantProps<typeof buttonVariants>["variant"]>,
			default: "default",
		},
		size: {
			type: String as PropType<VariantProps<typeof buttonVariants>["size"]>,
			default: "default",
		},
	},
	setup() {
		return { buttonVariants };
	},
});
