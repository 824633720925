










import { defineComponent } from "@vue/composition-api";
import { cx } from "@/lib/style/cva.config";

export default defineComponent({
	props: {
		day: {
			type: String,
			required: true,
		},
		time: {
			type: String,
			required: true,
		},
	},
	setup() {
		return { cx };
	},
});
