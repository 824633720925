






import { defineComponent } from "@vue/composition-api";
import Card from "@/lib/pfg/vue2/card/Card.vue";

export default defineComponent({
	components: { Card },
});
