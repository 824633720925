





























import { type PropType, defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import type { CallAttemptActivityLogViewModel } from "@/lib/backend/core.api";
import Button from "@/lib/pfg/vue2/button/Button.vue";
import Card from "../parts/Card.vue";
import Time from "../parts/Time.vue";
import Title from "../parts/Title.vue";

export default defineComponent({
	components: { Button, Card, SbTime: Time, SbTitle: Title },
	props: {
		day: {
			type: String,
			required: true,
		},
		time: {
			type: String,
			required: true,
		},
		log: {
			type: Object as PropType<CallAttemptActivityLogViewModel>,
			required: true,
		},
	},
	methods: {
		...mapActions("modal", ["openModal"]),
	},
});
