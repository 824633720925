






















































































































import { type PropType, computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { useRouteQuery } from "@vueuse/router";
import { addDays, format, formatISO, getWeek, getYear, parse, subYears } from "date-fns";
import { coreApi } from "@/lib/backend";
import type { IomodelsCoachappLidBezoek } from "@/lib/backend/club.api";
import type { PaginatedActivityLogViewModel } from "@/lib/backend/core.api";
import { buttonVariants } from "@/lib/pfg/components/button";
import { inputVariants } from "@/lib/pfg/components/input";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import Log from "./Log/index.vue";

type Years = Record<string, Weeks>;
type Weeks = Record<string, Days>;
type Days = Record<string, Time>;
type Time = Record<string, PaginatedActivityLogViewModel["data"]>;

export default defineComponent({
	components: { Log, Label },
	props: {
		memberId: {
			type: Number,
			required: true,
		},
		visits: {
			type: Array as PropType<IomodelsCoachappLidBezoek[]>,
			default: (): IomodelsCoachappLidBezoek[] => [],
		},
	},
	setup(props, { root }) {
		const activityType = useRouteQuery<
			unknown,
			| Parameters<typeof coreApi.members.memberActivityLogControllerGetActivityLogs>[1]["type"]
			| undefined
		>("type", undefined, {
			route: root.$route,
			router: root.$router,
			transform: (value) =>
				typeof value === "string" &&
				(["ASSESSMENT", "CALL_ATTEMPT", "CHECKIN", "NOTIFICATION_RECEIVED"] as const).includes(
					value,
				)
					? (value as Parameters<
							typeof coreApi.members.memberActivityLogControllerGetActivityLogs
						>[1]["type"])
					: undefined,
		});
		const fromDate = useRouteQuery<unknown, string>(
			"from",
			format(subYears(new Date(), 1), "yyyy-MM-dd"),
			{
				route: root.$route,
				router: root.$router,
				transform: (value) => {
					try {
						if (typeof value !== "string") {
							throw new Error("Invalid value");
						}

						return format(parse(value, "yyyy-MM-dd", subYears(new Date(), 1)), "yyyy-MM-dd");
					} catch (error) {
						return format(subYears(new Date(), 1), "yyyy-MM-dd");
					}
				},
			},
		);
		const toDate = useRouteQuery<unknown, string>(
			"to",
			format(addDays(new Date(), 1), "yyyy-MM-dd"),
			{
				route: root.$route,
				router: root.$router,
				transform: (value) => {
					try {
						if (typeof value !== "string") {
							throw new Error("Invalid value");
						}

						return format(parse(value, "yyyy-MM-dd", addDays(new Date(), 1)), "yyyy-MM-dd");
					} catch (error) {
						return format(addDays(new Date(), 1), "yyyy-MM-dd");
					}
				},
			},
		);

		const queryKey = computed(
			() =>
				[
					"member",
					props.memberId,
					"logs",
					fromDate.value,
					toDate.value,
					activityType.value,
				] as const,
		);

		const queryLogs = useQuery({
			queryKey,
			queryFn: async (context) =>
				await coreApi.members
					.memberActivityLogControllerGetActivityLogs(
						context.queryKey[1],
						{
							"where[timestamp][gte]": context.queryKey[3],
							"where[timestamp][lt]": context.queryKey[4],
							type: context.queryKey[5],
						},
						{ signal: context.signal },
					)
					.then((response) => response.data),
		});

		const items = computed(
			() =>
				queryLogs.data.value?.data.reduce<Years>((acc, log) => {
					const date = new Date(log.timestamp);
					const year = getYear(date);
					const week = getWeek(date);
					const fullDate = formatISO(date, { representation: "date" });
					const time = formatISO(date, { representation: "time" });

					return {
						...(acc || {}),
						[year]: {
							...(acc?.[year] || {}),
							[week]: {
								...(acc?.[year]?.[week] || {}),
								[fullDate]: {
									[time]: [...(acc?.[year]?.[week]?.[fullDate]?.[time] || []), log],
									...(acc?.[year]?.[week]?.[fullDate] || {}),
								},
							},
						},
					};
				}, {}) ?? {},
		);

		return { queryLogs, items, buttonVariants, inputVariants, activityType, fromDate, toDate };
	},
});
