
















































































import { type PropType, defineComponent } from "@vue/composition-api";
import { daysInWeek, differenceInDays, differenceInWeeks, getWeek, getYear } from "date-fns";
import { nl } from "date-fns/locale";
import type { Options } from "highcharts";
import { DateTime } from "klokwerk";
import { mapActions, mapState } from "pinia";
import ActivityLogs from "@/components/Activity/Logs.vue";
import { useGebruikerStore } from "@/pinia/gebruiker";
import type { RouteProps } from "@/router/types";

type Period = "sixWeeks" | "threeMonths" | "oneYear" | "allTime";

type Data = {
	loading: boolean;
	graphPeriod?: Period;
	periodsToShow: Period[];
	options: Options;
};

export default defineComponent({
	components: { ActivityLogs },
	props: {
		memberId: {
			type: Number as PropType<RouteProps<"member">["memberId"]>,
			required: true,
		},
	},
	setup(props) {
		return { props };
	},
	data(): Data {
		return {
			loading: false,
			graphPeriod: undefined,
			periodsToShow: ["sixWeeks", "threeMonths"],
			options: {
				chart: {
					marginTop: 60,
					type: "column",
				},
				xAxis: {
					title: {
						text: "week",
					},
					crosshair: false,
				},
				yAxis: {
					tickInterval: 1,
					min: 0,
					title: {
						text: "bezoeken",
					},
					plotLines: [
						{
							color: "#7b7aa0",
							dashStyle: "shortdash",
							width: 2,
						},
					],
				},
				series: [
					{
						name: "aantal bezoeken",
						data: [],
						type: "column",
					},
					{
						color: "#7b7aa0",
						name: "streef bezoeken",
						type: "line",
						dashStyle: "shortdash",
						// @ts-expect-error Highcharts type definitions are not fully up-to-date
						marker: {
							enabled: false,
						},
					},
				],
				plotOptions: {
					series: {
						events: {
							legendItemClick: function (event: Event) {
								event.preventDefault();
							},
						},
					},
				},
			},
		};
	},
	computed: {
		...mapState(useGebruikerStore, {
			id: "id",
			bezoekenSortedByDate: "bezoekenSortedByDate",
			doelFrequentie: ({ doelFrequentie }) => doelFrequentie?.doelfrequentie ?? 1,
			lastBezoek: ({ bezoekenSortedByDate }) => {
				const lastBezoek = bezoekenSortedByDate.at(0);

				if (!lastBezoek) return "";

				return new Date(lastBezoek.timestamp).toLocaleDateString();
			},
		}),
	},
	watch: {
		$route: "check",
		graphPeriod(newGraphPeriod: Period) {
			switch (newGraphPeriod) {
				default:
				case "sixWeeks":
					this.updateGraph(new DateTime().setDate((current) => current.date - daysInWeek * 6));
					break;
				case "threeMonths":
					this.updateGraph(new DateTime().setMonth((current) => current.month - 3));
					break;
				case "oneYear":
					this.updateGraph(new DateTime().setFullYear((current) => current.fullYear - 1));
					break;
				case "allTime":
					this.updateGraph();
					break;
			}
		},
	},
	async created() {
		await this.check();
	},
	methods: {
		...mapActions(useGebruikerStore, ["getBezoeken", "getDoelFrequentie", "set"]),
		async check() {
			this.loading = true;

			if (this.id !== this.props.memberId) this.set(this.props.memberId);

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.getBezoeken();
			await this.getDoelFrequentie();
			this.graphPeriod = "sixWeeks";
		},
		bezoekenLastAmountOfDays(days: number) {
			if (!this.bezoekenSortedByDate.length) return 0;

			const NOW = new Date();

			return this.bezoekenSortedByDate.filter((bezoek) => {
				const bezoekDate = new Date(bezoek.timestamp);

				if (differenceInDays(NOW, bezoekDate) < days) return bezoek;

				return false;
			}).length;
		},
		updateGraph(startDateTime?: DateTime) {
			if (!this.bezoekenSortedByDate) return [];

			const backupStart = this.bezoekenSortedByDate.at(-1)?.timestamp;
			const backupStartDateTime = backupStart ? new Date(backupStart) : new Date();

			const start = startDateTime?.native ?? backupStartDateTime;

			// Set plotline
			if (this.doelFrequentie) {
				// @ts-expect-error Not correctly safeguarded
				this.options.yAxis.plotLines[0].value = this.doelFrequentie;
				// @ts-expect-error Not correctly safeguarded
				this.options.yAxis.minRange = this.doelFrequentie;
			}

			// amount of weeks we go back in time
			const weeksBack = differenceInWeeks(new Date(), start) - 1;

			// week (year) text used for x-axis
			const weeks: string[] = [];
			const columnData: [number, number][] = [];
			for (let index = weeksBack; index >= 0; index -= 1) {
				const dateTime = new DateTime().setDate((current) => current.date - daysInWeek * index);
				const week = getWeek(dateTime.native, {
					locale: nl,
					weekStartsOn: 1,
				});

				const data = this.bezoekenSortedByDate.filter(
					(visit) =>
						getWeek(new Date(visit.timestamp), {
							locale: nl,
							weekStartsOn: 1,
						}) === week,
				);

				weeks.push(`Week ${week} - ${getYear(dateTime.native)}`);
				columnData.push([weeksBack - index, data.length]);
			}

			// @ts-expect-error Not correctly safeguarded
			this.options.xAxis.categories = weeks;

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.options.series![0].data = columnData;
		},
	},
});
